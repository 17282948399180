import React from 'react';
import { NavLink } from 'react-router-dom';
import Terminal from '../components/terminal';

export default function Home() {
  return (
    <div>
      <div id="signature" />
      <div className="middle-circle">
        <h1 className="giant">Hi there!</h1>
        <p className="large">
          I&apos;m
          <strong> Enrico. </strong>
          &nbsp;I thrive on exploring, analyzing, and innovating with various technologies and
          devices. Feel free to ask the chatbot below any questions to learn more about my
          background. Please don&apos;t hesitate to reach out if you encounter any issues or have
          potential projects in mind. I&apos;m always eager to collaborate! Check out my &nbsp;
          <NavLink to="/blog">new blog</NavLink>
          &nbsp; for insights into my latest work.
        </p>
        <Terminal />
      </div>
    </div>
  );
}
