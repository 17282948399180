/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import getPostDetails from '../common';
// import ImageGallery from 'react-image-gallery';

export default function BlogPost({ post }) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState();
  const [loaded, setLoaded] = useState(false);

  const mql = window.matchMedia('(max-width:400px)').matches;

  useEffect(() => {
    async function loadPost() {
      if (slug) {
        const postContent = await getPostDetails().then(
          (posts) =>
            posts.filter((currentPost) => {
              return currentPost.slug === slug;
            })[0]
        );
        setBlogPost(postContent);
      } else {
        setBlogPost(post);
      }

      setLoaded(true);
    }

    if (!loaded) {
      loadPost();
    }
  }, [loaded, slug]);

  const renderers = {
    code: ({ value }) => {
      return (
        <SyntaxHighlighter
          showLineNumbers
          style={dark}
          language="cpp"
          customStyle={{
            fontSize: '14px',
          }}
          codeTagPros={{
            style: {
              fontSize: 'inherit',
            },
          }}
        >
          {value}
        </SyntaxHighlighter>
      );
    },
  };

  return (
    loaded && (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: mql ? 'column' : 'row',
            alignItems: 'flex-end',
          }}
        >
          <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(slug)}>
            <u>{post.title}</u>
          </h1>

          <span style={{ paddingLeft: '10px' }}>
            <h3>
              <u>{blogPost.title}</u>
            </h3>
          </span>
        </div>

        <ReactMarkdown renderers={renderers} linkTarget="_blank">
          {blogPost.content}
        </ReactMarkdown>
      </div>
    )
  );
}
BlogPost.defaultProps = {
  post: {
    title: '',
    slug: '',
    date: '',
    content: '',
    images: [],
  },
};

BlogPost.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};
