/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import * as uuid from 'uuid';
import { useNavigate } from 'react-router-dom';
import '../css/blog.css';
import getPostDetails from '../common';
// import BlogPost from '../components/blogPost';

export default function Blog() {
  const navigate = useNavigate();
  const [hiddenInfo, setHiddenInfo] = useState(true);
  // const [allPosts, setAllPosts] = useState([]);
  const [postDetails, setPostDetails] = useState([]);
  const [loaded, setLoaded] = useState(false);

  function showInfo() {
    setHiddenInfo(!hiddenInfo);
  }

  const renderPostList = () => {
    const result = [];
    postDetails.forEach((postDetail) => {
      const formattedDate = postDetail.date;
      result.push(
        <li
          key={uuid.v4()}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`${postDetail.slug}`)}
        >
          <u>
            {postDetail.title}
            {` (${formattedDate})`}
          </u>
        </li>
      );
    });
    return result;
  };

  useEffect(() => {
    async function loadPosts() {
      const allPosts = await getPostDetails().then((res) => res); // Wait for getPostDetails to finish
      setPostDetails(allPosts);
      setLoaded(true);
    }

    if (!loaded) {
      loadPosts();
    }
  }, [loaded]);

  return (
    <div>
      <div className="title-bar">
        <h2>My Thought Repository</h2>
        <i
          className="fa icon alt fa-question-circle"
          style={{ paddingLeft: '10px' }}
          onClick={() => showInfo()}
        />
      </div>

      <p className={hiddenInfo ? 'hidden' : ''}>
        This is a place where I write about any projects I have currently going on but also any
        experiences or thoughts I might have. As of now, I think I&apos;m going to keep this space
        purely about technology or technology related topics and not a personal journal. That does
        not mean I will not riddle the blog posts with personal opinions and thoughts. Enjoy!
      </p>
      <div style={{ display: 'flex' }}>
        {/*
        <div style={{ maxWidth: '65%' }}>
          {postDetails
            ? postDetails.map((post) => {
                return <BlogPost key={uuid.v4()} post={post} />;
              })
            : []}
        </div>
        */}
        <div className="postMenu" style={{ maxWidth: '25%' }}>
          All Posts:
          <ul>{renderPostList()}</ul>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line no-unused-expressions
Blog.defaultProps;
