import React, { useState, useEffect } from 'react';
import '../css/terminal.css';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'; // Import axios
import config from '../config';

export default function Terminal() {
  const [history, setHistory] = useState([
    'Chatbot says: "Hi! I\'m a chatbot here to answer any questions you might have about Enrico."',
  ]);
  const [elementAnchor, setElementAnchor] = useState(null);
  const [currentCommand, setCurrentCommand] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const renderHistory = () => {
    const components = history.map((command) => {
      return <li key={uuidv4()}>{command}</li>;
    });
    if (elementAnchor) {
      elementAnchor.scrollIntoView({ behavior: 'smooth' });
    }
    return components;
  };

  const handleSendMessage = async () => {
    if (currentCommand === '') {
      return; // Don't send empty messages
    }
    setIsLoading(true);
    setHistory((oldHistory) => [...oldHistory, `You said: "${currentCommand}"`]);

    try {
      const response = await axios.post(
        `${config.API_URL}/chat/`,
        {
          message: currentCommand,
        },
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const result = response.data.response;
      setHistory((oldHistory) => [...oldHistory, `Chatbot says: "${result}"`]);
    } catch (error) {
      setHistory((oldHistory) => [
        ...oldHistory,
        `Chatbot says: "Error with status ${error.response.status} returned from chatbot API."`,
      ]);
    } finally {
      setIsLoading(false);
      setCurrentCommand(''); // Clear command after sending
      if (history.length > 5) {
        history.splice(0, 2);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  // Handle initial focus on the input field
  useEffect(() => {
    if (elementAnchor) {
      elementAnchor.focus();
    }
  }, [elementAnchor]);

  return (
    <div className="terminal">
      <div className="history">
        <ul style={{ margin: 0, marginBlockStart: 0, paddingInlineStart: 0, marginBlockEnd: 0 }}>
          {renderHistory()}
        </ul>
      </div>

      <div className="currentCommand">
        <ul style={{ margin: 0, marginBlockStart: 0, paddingInlineStart: 0, marginBlockEnd: 0 }}>
          <li>
            {isLoading ? (
              [
                <i className="fa fa-cog fa-spin fa-1x fa-fw" />,
                <span className="sr-only">Loading...</span>,
              ]
            ) : (
              <input
                id="command"
                ref={(el) => {
                  setElementAnchor(el);
                }}
                type="text"
                placeholder="Type here..."
                onKeyDown={(event) => handleKeyDown(event)}
                onChange={(event) => setCurrentCommand(event.target.value)}
                value={currentCommand}
              />
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
