import axios from 'axios';
import config from './config';

export default async function getPostDetails() {
  const metaDataUrl = `${config.API_URL}/blog/post/metadata`;
  const metaDataResponse = await axios.get(metaDataUrl).then((res) => {
    return res.data;
  });
  const x = await metaDataResponse.map(async (res) => {
    const metaData = res;
    const contentUrl = `${config.API_URL}/blog/post?filename=${metaData.file_name}`;
    const contentResponse = await axios.get(contentUrl).then((res2) => {
      const content = res2.data;
      return {
        title: metaData.title,
        date: metaData.date,
        content,
        slug: metaData.file_name,
        images: metaData.images,
      };
    });
    return contentResponse;
  });
  return Promise.all(await x);
}
